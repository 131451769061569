<template>
  <div id="Case">
    <h2>涿州市实验中学</h2>
    <!-- <p>Zhuozhou Experimental High School</p> -->
    <div class="case-box">
      <p class="pc">
        河北涿州实验中学始建于1986年，先后荣获保定市“规范化学校”、“和谐校园”；河北省“德育示范校”、“安全文明校园”、“示范家长学校”、“依法治校示范校”、“礼仪教育示范校”等市级以上荣誉达26项。2020年，全校一次性建设了24个智笔课堂互动教学班。课堂中，学生通过多种答题互动方式，增强课堂师生互动效果，答题进度及答题结果实时反馈，进行针对性讲解；同时，保留学生纸笔书写习惯，答题过程实时记录，显示学生作答步骤，思考时长及知识点掌握情况。老师们实践这种教学模式的过程中，积累经验，开拓创新，走出了自己互动教学新的道路。
      </p>
      <p class="title pc">教研服务推进常态化应用：</p>
      <p class="pc">
        教研小组协同机制——三部曲“共备、共听、共议”，以教研小组为单位进行定期教研、集体教研活动，组内成员一起探讨、共享信息化与学科融合、思路和想法，共同进步，创造浓郁的教研氛围，整体提升老师们的信息素养和教学质量。
      </p>
      <p class="title pc">教师大赛活动：</p>
      <p class="pc">
        通过教师大赛活动，推动教师使用新型信息技术，创建新型课堂，实现教学模式变革，提升课堂教学质量
      </p>
      <p class="mobile">
        河北涿州实验中学始建于1986年，先后荣获保定市“规范化学校”、“和谐校园”；河北省“德育示范校”、“安全文明校园”、“示范家长学校”、“依法治校示范校”、“礼仪教育示范校”等市级以上荣誉达26项。2020年，全校一次性建设了24个智笔课堂互动教学班。课堂中，学生通过多种答题互动方式，增强课堂师生互动效果，答题进度及答题结果实时反馈，进行针对性讲解；同时，保留学生纸笔书写习惯，答题过程实时记录，显示学生作答步骤，思考时长及知识点掌握情况。老师们实践这种教学模式的过程中，积累经验，开拓创新，走出了自己互动教学新的道路。
      </p>
      <p class="title mobile">教研服务推进常态化应用：</p>
      <p class="mobile">
        教研小组协同机制——三部曲“共备、共听、共议”，以教研小组为单位进行定期教研、集体教研活动，组内成员一起探讨、共享信息化与学科融合、思路和想法，共同进步，创造浓郁的教研氛围，整体提升老师们的信息素养和教学质量。
      </p>
      <p class="title mobile">教师大赛活动：</p>
      <p class="mobile last">
        通过教师大赛活动，推动教师使用新型信息技术，创建新型课堂，实现教学模式变革，提升课堂教学质量
      </p>
      <img src="../../../assets/images/partnersCase/cases/case10.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Case',
  metaInfo: {
    title: '伙伴与客户',
    meta: [
      { name: 'keywords', content: '晓羊集团' },
      { name: 'description', content: '' },
    ],
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  mounted() {
    //模拟触发滚动1像素，为了触发在首屏应该展示的动画（numbers动画），也能顺便返回页面顶部
    window.scrollTo(0, 1)
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
%Case {
  width: 100%;
  padding-top: 180px;
  position: relative;
  background: $--color-white;
  @include px2vw('padding-bottom', 140);
  // @include px2vw('padding-right', 240);
  text-align: center;
  h2 {
    font-size: 32px;
  }
  > p {
    font-size: 24px;
    color: #333333;
    position: relative;
    &::after {
      content: ' ';
      width: 66px;
      height: 3px;
      display: inline-block;
      position: absolute;
      left: 48%;
      margin-top: 45px;
      background: $--color-primary;
    }
  }
  .case-box {
    margin: 60px auto 0;
    width: 90%;
    max-width: 1320px;

    p {
      line-height: 32px;
      font-size: 18px;
      text-align: justify;
      color: #666;
    }
    .title {
      font-size: 18px;
      font-weight: bold;
      color: $--color-primary;
    }
    img {
      width: 50%;
      margin-top: 20px;
    }
  }
}

.view-pc > #Case {
  @extend %Case;
  @media screen and (max-width: 1200px) {
    .case-box {
      width: 90%;
      img {
        width: 80%;
      }
    }
  }
}

.view-mobile > #Case {
  @extend %Case;
  h2 {
    font-size: 22px;
  }
  .case-box {
    width: 90%;
    margin-top: 30px !important;
    p {
      font-size: 14px;
      line-height: 28px;
      margin: 0 0 14px;
      &:first-child {
        margin-top: 30px;
      }
    }
    .last {
      margin-bottom: 0;
    }
  }
  margin-top: 44px;
  padding-top: 15px;
  > p {
    font-size: 14px;
    &::after {
      left: 42%;
      margin-top: 35px;
    }
  }
  .case-box {
    margin-top: 40px;
    img {
      width: 100%;
    }
  }
  @media screen and (max-width: 768px) {
    > p {
      &::after {
        left: 45%;
        margin-top: 35px;
      }
    }
  }
}
</style>
